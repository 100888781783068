<template>
<section>
    <div class="flex items-center">
        <div class="text-md text-gray-600 dark:text-white/75 whitespace-nowrap">{{ data.title }}</div>
        <n-popover
            v-if="data.dataHelp"
            style="width: 300px" trigger="hover" placement="top"
            class="rounded-md bg-main">
            <template #trigger>
                <n-icon
                    class="cursor-pointer text-xl mx-2 text-main">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span
                class="notes text-xs text-white"
                v-html="data.dataHelp">
            </span>
        </n-popover>
    </div>
    <div class="flex mt-2">
        <n-select
            filterable
            size="large"
            class="text-left"
            :status="status"
            :options="options"
            :placeholder="data.placeholder"
            :loading="data.loading"
            :disabled="data.loading"
            :render-tag="renderTag"
            :render-label="renderLabel"
            :value="modelValue !== -1 ? modelValue : null"
            @update:value="$emit('update:modelValue', $event)" />
        <n-button
            strong
            size="large"
            class="rounded-md text-white/90 ml-2"
            :color="gl.mainColor"
            :disabled="data.loading || data.buttonCheckDisabled || checkBalanceLoading"
            :loading="data.loading || checkBalanceLoading"
            @click="onCheckBalance">
            {{ data.buttonCheckLabel }}
        </n-button>
    </div>
    <section class="flex justify-end mt-4">
        <n-button
            strong
            secondary
            size="small"
            class="rounded-md mr-1 flex-grow"
            @click="showCreateWalletModal = true, walletId = null">
            + {{ data.buttonCreateLabel }}
        </n-button>
        <n-button
            strong
            secondary
            size="small"
            icon-placement="left"
            class="rounded-md ml-1 flex-grow"
            :disabled="data.buttonEditDisabled"
            @click="showCreateWalletModal = true, walletId = modelValue">
            <template #icon>
                <n-icon
                    class="cursor-pointer text-xl mx-2">
                    <Edit16Regular />
                </n-icon>
            </template>
            {{ data.buttonEditLabel }}
        </n-button>
    </section>
    <div v-if="symbolBalance || symbolTotalBalance" class="w-full flex justify-between mt-6">
        <n-statistic
            v-if="symbolBalance"
            tabular-nums
            class="text-left"
            :label="bots.localization['bots_new_wallet_checkbalance_available']">
            <n-number-animation
                :from="0"
                :to="+symbolBalance"
                :precision="8"
            />
        </n-statistic>
        <n-statistic
            v-if="symbolTotalBalance"
            tabular-nums
            class="text-left"
            :label="bots.localization['bots_new_wallet_checkbalance_total']">
            <n-number-animation
                :from="0"
                :to="+symbolTotalBalance"
                :precision="8"
            />
        </n-statistic>
    </div>
    <rb-wallet-cr
        :wallet-id="walletId"
        :show="showCreateWalletModal"
        :exchange-id="exchangeRef"
        :coin-symbol-for-wallet="coinSymbolForWallet"
        :can-create-new-api-key="false"
        @update:show="showCreateWalletModal = $event"
        @updateWallet="$emit('updateWallet', $event)"
        @addNewWallet="$emit('addNewWallet', $event)"
        @addNewApiKey="$emit('addNewApiKey', $event)" />
</section>
</template>

<script>
// vue
import { h, ref, computed, watch, onMounted } from 'vue';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useBots } from '@store/bots';

// services
import WalletsService from '@services/walletsService';

// components
import RbCoin from '@components/rb-coin';
import RbSelect from '@components/rb-select';
import RbWalletCr from '@components/rb-wallet-cr';

// icons
import { Dismiss16Filled, QuestionCircle20Filled, Warning24Filled, Edit16Regular } from '@vicons/fluent';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NModal,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NStatistic,
    NNumberAnimation } from 'naive-ui';

export default {
    name: 'BotWallet',
    emits: ['update:modelValue', 'walletReady'],
    props: {
        apiKey: [String, Number],
        modelValue: [String, Number],
        exchangeRef: [String, Number],
        coinSymbolForWallet: String,
        data: {
            type: Object,
            required: true,
        },
        exchangeInfoRef: {
            type: Object,
            required: true,
        },
        pairWalletsRefs: {
            type: Array,
            required: true,
        },
        walletsRefs: {
            type: Array,
            required: true,
        },
        apiKeysRefs: {
            type: Array,
            required: true,
        },
        status: {
            type: String,
            default: undefined,
        },
        msg: {
            type: String,
        },
    },
    components: {
        NIcon,
        NCard,
        NAlert,
        NModal,
        RbCoin,
        NInput,
        NButton,
        NSelect,
        NPopover,
        NDivider,
        RbSelect,
        NStatistic,
        RbWalletCr,
        Edit16Regular,
        Warning24Filled,
        Dismiss16Filled,
        NNumberAnimation,
        QuestionCircle20Filled,
    },
    setup(props) {
        // store
        const gl = useGl();
        const refs = useRefs();
        const bots = useBots();

        // vars
        const walletMode = ref('create');
        const balancesRefs = ref();
        const showCreateWalletModal = ref(false);
        const checkBalanceLoading = ref(false);
        const walletId = ref();

        const options = computed(() => props.pairWalletsRefs?.length ? props.pairWalletsRefs.map(el => ({
            value: el.id,
            label: el.name,
            ...el,
        })) : []);

        const symbolBalance = computed(() => balancesRefs.value?.[props.coinSymbolForWallet] || '');
        const symbolTotalBalance = computed(() => balancesRefs.value?.[`${props.coinSymbolForWallet}_total`] || '');

        const currenciesRefs = computed(() => props.exchangeInfoRef?.currencies || []);

        watch(() => props.apiKey, () => {
            balancesRefs.value = null;
        });

        watch(() => props.modelValue, () => {
            balancesRefs.value = null;
        });

        const renderTag = ({ option }) => {
            return h('div', {
                class: 'w-full flex items-center',
            }, [
                h('div',{
                    class: 'h-full',
                }, h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'w-5 h-5 mr-2',
                })),
                h('div', null, [
                    h('div', {
                        class: 'flex items-end',
                    }, [
                        h('span', {
                            class: 'text-sm font-semibold text-base',
                        }, option.amount),
                        h('span', {
                            class: 'uppercase ml-1 opacity-80 text-[12px]',
                        }, option.currency ),
                    ]),
                    h('div', {
                        class: '-mt-2',
                    }, 
                    h('span', {
                        class: 'text-xs opacity-60',
                    }, option.name)),
                ]),
            ]);
            // return h('div', {
            //     class: 'w-full flex items-center',
            // }, [
            //     h(RbCoin, {
            //         coin: { ...option, code_name: 'rb-cc__' + option.currency },
            //         class: 'w-5 h-5 mr-2',
            //     }),
            //     h('span', {
            //         class: 'text-sm text-main font-semibold mr-2',
            //     }, option.amount),
            //     h('span', {
            //         class: 'text-sm flex-grow',
            //     }, option.name.length > 15 ? `${option.name.slice(0, 15)}...` : option.name),
            //     h('span', {
            //         class: 'uppercase ml-2 opacity-50',
            //     }, option.currency),
            // ]);
        };

        const renderLabel = option => {
            return h('div', {
                class: 'w-full flex items-center py-[1px]',
            }, [
                h('div',{
                    class: 'h-full',
                }, h(RbCoin, {
                    coin: { ...option, code_name: 'rb-cc__' + option.currency },
                    class: 'w-5 h-5 mr-2',
                })),
                h('div', null, [
                    h('div', {
                        class: 'flex',
                    }, [
                        h('span', {
                            class: 'text-sm font-semibold',
                        }, option.amount),
                        h('span', {
                            class: 'uppercase ml-1 opacity-80',
                        }, option.currency ),
                    ]),
                    h('div', {
                        class: '-mt-1',
                    }, 
                    h('span', {
                        class: 'text-xs opacity-60',
                    }, option.name)),
                ]),
            ]);
            // return h('div', {
            //     class: 'w-full py-1 flex flex-row items-center',
            // }, [
            //     h('span', {
            //         class: 'text-xs flex-grow',
            //     }, option.name),
            //     h('span', {
            //         class: 'text-sm font-semibold',
            //     }, option.amount),
            //     h(RbCoin, {
            //         coin: { ...option, code_name: 'rb-cc__' + option.currency },
            //         class: 'ml-2 w-4',
            //     }),
            //     h('span', {
            //         class: 'uppercase ml-2 mr-8 opacity-50',
            //     }, option.currency ),
            // ]);
        };

        const exchange = (exchange) => {
            return refs.exchanges.find(el => el.id === exchange);
        };

        const currency = (icon) => {
            return refs.currencies.find(el => el.id === icon);
        };

        const onCheckBalance = async () => {
            checkBalanceLoading.value = true;

            const prepare = await WalletsService.getBalances(props.apiKey);

            if (!prepare.data.status) {
                prepare.data.errors.forEach(({ msg }) => {
                    gl.showNotification({
                        type: 'error',
                        msg,
                    });
                });

                // reset balancesRefs
                balancesRefs.value = null;
            } else {
                balancesRefs.value = prepare.data.balances;
            }

            checkBalanceLoading.value = false;
        };

        onMounted(() => {
            // console.log('pairWalletsRefs', props.pairWalletsRefs);
        });

        return {
            gl,
            bots,
            options,
            walletId,
            walletMode,
            symbolBalance,
            currenciesRefs,
            symbolTotalBalance,
            checkBalanceLoading,
            showCreateWalletModal,
            exchange,
            renderTag,
            renderLabel,
            onCheckBalance,
        };
    },
};
</script>